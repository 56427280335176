.root {
  @apply bg-surface z-[52] rounded-md shadow-elevation-300 max-h-screen;

  .viewport {
    @apply flex flex-col overflow-x-hidden overflow-y-auto p-2;
  }
  .item {
    @apply w-full text-body-sm-medium gap-2 flex items-center justify-between px-4 uppercase relative select-none outline-none fill-primary-200;
    min-width: 10.5625rem;
    min-height: 2.75rem;
    border-radius: 0.325rem;
    .indicator {
      @apply w-6 inline-flex items-center justify-center;
    }
    &[data-highlighted],
    &:hover {
      @apply bg-primary-500 fill-secondary-300 cursor-pointer;
      .indicator {
        @apply fill-secondary-300;
      }
    }
    &[data-disabled] {
      @apply pointer-events-none;
    }
  }

  &[data-variant="pick"] {
    width: var(--radix-select-trigger-width);
  }
}
.root[data-variant="sport"] {
  @apply bg-secondary-300 z-[52] rounded-md shadow-elevation-300 max-h-screen;

  .viewport {
    @apply flex flex-col overflow-x-hidden overflow-y-auto p-2;
  }
  .item {
    @apply w-full text-body-sm-medium gap-2 flex items-center justify-between px-4 uppercase relative select-none outline-none fill-primary-200;
    min-width: 10.5625rem;
    min-height: 2.75rem;
    border-radius: 0.325rem;
    .indicator {
      @apply w-6 inline-flex items-center justify-center text-secondary-300;
    }
    .text {
      @apply text-white;
    }
    .text > svg {
      @apply text-primary-500;
    }
    &:hover {
      .text {
        @apply text-secondary-300;
      }
      .text > svg {
        @apply text-secondary-300;
      }
    }
    &[data-state="checked"] {
      .indicator {
        @apply !text-primary-500;
      }
    }
    &[data-state="checked"]:hover, &[aria-selected="true"] {
      .indicator {
        @apply !text-secondary-300;
      }
      .text {
        @apply text-secondary-300;
      }
      .text > svg {
        @apply text-secondary-300;
      }
    }
    &[data-disabled] {
      @apply pointer-events-none;
    }
  }
}
.text {
  @apply flex items-center gap-2 text-secondary-300 fill-secondary-300;
}

.trigger[data-variant="sport"] {
  @apply text-title-sm-medium rounded-sm  py-1 pl-2 pr-2 outline-none bg-dark text-white fill-primary hover:bg-primary-600 hover:text-dark active:bg-primary-400 h-[26px] gap-1;
  &:hover {
    @apply bg-dark;
  }
  &:hover .text {
    @apply text-white;
  }
  .text {
    @apply text-white;
  }
  .text > svg {
    @apply text-primary-500;
  }
}
.trigger[data-variant="card"] {
  .text > :global(svg) {
    @apply hidden;
  }
  &[data-dark="true"] {
    @apply bg-white/10;
    .text {
      @apply text-white;
    }
    & :global(svg) {
      @apply fill-white;
    }
  }
}
.trigger[data-variant="team-sm"] {
  .text {
    @apply text-white;
  }
}

/* .trigger {
  .standard {
    composes: root default standard secondary from "@/components/primitives/button.moddule.css";
  }
} */

.medium {
  font-weight: 500;
}
.standardTrigger {
  composes: trigger;
  composes: root default standard md secondary from "@/components/primitives/button.module.css";
  &&&&&&&& {
    @apply font-medium;
  }
}

.listItemMobile {
    @apply flex px-5 py-4 gap-4 hover:bg-surface-600 rounded-none outline-none w-full text-body-sm-medium items-center justify-between uppercase relative select-none fill-primary-200;
    min-width: 10.5625rem;
    min-height: 2.75rem;
    .indicator {
      @apply w-6 inline-flex items-center justify-center;
    }
    &[data-highlighted],
    &:hover {
      @apply bg-primary-500 fill-secondary-300 cursor-pointer;
      .indicator {
        @apply fill-secondary-300;
      }
    }
    &[data-disabled] {
      @apply pointer-events-none;
    }
  }

.listItem {
    @apply w-full text-body-sm-medium gap-2 flex items-center justify-between px-4 uppercase relative select-none outline-none fill-primary-200;
    min-width: 10.5625rem;
    min-height: 2.75rem;
    border-radius: 0.325rem;
    .indicator {
      @apply w-6 inline-flex items-center justify-center;
    }
    &[data-highlighted],
    &:hover {
      @apply bg-primary-500 fill-secondary-300 cursor-pointer;
      .indicator {
        @apply fill-secondary-300;
      }
    }
    &[data-disabled] {
      @apply pointer-events-none;
    }
  }

.listIndicator {
    @apply w-6 inline-flex items-center justify-center fill-primary-200;
    &:hover {
      @apply fill-secondary-300;
    }
}